<template>
	<div class="transaction-item" @click="showTransactionDetails">
		<div class="transaction-header">
			<div class="transaction-date">{{ formatDate(transaction.timestamp) }}</div>
			<div :class="['transaction-amount', amountClass]">
				<span v-if="!transaction.confirmed" class="pending-status">(Pending)</span>
				{{ amountPrefix }}
				<span>{{ formattedAmount.integer }}</span>
				<span v-if="formattedAmount.decimal > 0" class="small-decimal">.{{ formattedAmount.decimal }}</span>
			</div>
		</div>
		<div class="transaction-type">
			<i :class="transactionIcon"></i>
			<span v-if="!isSpecialAction">{{ transactionType }}</span>
			<span v-if="isCreateContract">Contract</span>
			<span v-if="isContractBurn">Burn</span>
			<span v-if="isContractMint">Mint</span>
		</div>
		<small class="transaction-address" v-if="showReceiverAddress">
			{{ transaction.receiver ? 'From: ' + truncateAddress(transaction.sender) : '' }}
		</small>
		<small class="transaction-address" v-if="showSenderAddress">
			{{ transaction.sender ? 'To: ' + truncateAddress(transaction.receiver) : '' }}
		</small>
		<small class="transaction-address" v-if="showCreateContractAddress">
			{{ transaction.receiver ? 'To: ' + truncateAddress(transaction.receiver) : '' }}
		</small>
		<small class="transaction-address" v-if="isContractBurn">
			{{ transaction.sender ? 'From: ' + truncateAddress(transaction.sender) : '' }}
		</small>
	</div>
</template>
<script>
	export default {
		props: ['transaction', 'walletAddress'],
		computed: {
			formattedAmount() {
				return this.formatAmount(this.transaction.amount);
			},
			amountClass() {
				if (this.transaction.action === 'contract_burn') {
					return 'negative';
				}
				return this.transaction.sender === this.walletAddress && !this.isSpecialAction ? 'negative' : 'positive';
			},
			amountPrefix() {
				if (this.transaction.action === 'contract_burn') {
					return '-';
				}
				return this.transaction.sender === this.walletAddress && !this.isSpecialAction ? '-' : '+';
			},
			transactionIcon() {
				if (this.transaction.action === 'contract_burn') {
					return 'fas fa-arrow-up';
				}
				return this.transaction.sender === this.walletAddress && !this.isSpecialAction ? 'fas fa-arrow-up' : 'fas fa-arrow-down';
			},
			transactionType() {
				return this.transaction.sender === this.walletAddress ? 'Transfer' : 'Deposit';
			},
			isSpecialAction() {
				return ['create_contract', 'contract_mint', 'contract_burn'].includes(this.transaction.action);
			},
			isCreateContract() {
				return this.transaction.sender === this.walletAddress && this.transaction.action === 'create_contract';
			},
			isContractBurn() {
				return this.transaction.sender === this.walletAddress && this.transaction.action === 'contract_burn';
			},
			isContractMint() {
				return this.transaction.sender === this.walletAddress && this.transaction.action === 'contract_mint';
			},
			showReceiverAddress() {
				return this.transaction.receiver === this.walletAddress && !this.isSpecialAction;
			},
			showSenderAddress() {
				return this.transaction.sender === this.walletAddress && !this.isSpecialAction;
			},
			showCreateContractAddress() {
				return ['create_contract', 'contract_mint'].includes(this.transaction.action);
			}
		},
		methods: {
			formatDate(timestamp) {
				const date = new Date(timestamp * 1000);
				return date.toISOString().split('T')[0];
			},
			truncateAddress(address) {
				return `${address.slice(0, 12)}......${address.slice(-12)}`;
			},
			formatAmount(amount) {
				const amountFloat = parseFloat(amount) / 1e8;
				const [integer, decimal = '00000000'] = amountFloat.toFixed(8).split('.');
				return {
					integer: new Intl.NumberFormat().format(integer),
					decimal
				};
			},
			showTransactionDetails() {
				this.$emit('view-details', this.transaction);
			}
		}
	};
</script>
<style scoped>

</style>