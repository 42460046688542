<template>
	<div class="dashboard-container">
		<BalanceComponent v-if="selectedTab !== 'settings'" @dispatch:wallet="getWallet" @update:balance="updateBalance" @update:transactions="updateTransactions" @update:symbol="updateSymbol" @refreshWallet="refreshWallet" @update:mgxBalance="updateMgdbBalance" :fetchWallet="fetchWallet" />
		<WalletComponent v-if="selectedTab === 'wallet'" :walletData="walletData" :balance="balance" :balancePending="balancePending" :transactions="filteredTransactions" :currentPage="currentPage" :totalPages="totalPages" :currentSymbol="currentSymbol" @update:currentPage="updateCurrentPage" />
		<SendComponent v-if="selectedTab === 'send'" :balance="balance" :balancePending="balancePending" :currentSymbol="currentSymbol" :nativeSymbol="nativeSymbol" :mgxBalance="mgxBalance" @refreshWallet="refreshWallet" />
		<ReceiveComponent :balancePending="balancePending" v-if="selectedTab === 'receive'" />
		<!-- Add other components similarly for other tabs -->
	</div>
</template>
<script>
	import WalletComponent from './WalletComponent.vue';
	import SendComponent from './SendComponent.vue';
	import ReceiveComponent from './ReceiveComponent.vue';
	import BalanceComponent from './BalanceComponent.vue';
	export default {
		props: ['selectedTab'],
		components: {
			WalletComponent,
			SendComponent,
			ReceiveComponent,
			BalanceComponent
		},
		data() {
			return {
				walletAdress: localStorage.getItem('address'),
				walletData: null,
				nativeSymbol: 'MGDB',
				mgxBalance: 0,
				balance: 0,
				balancePending: 0,
				transactions: [],
				currentPage: 1,
				fetchWallet: null,
				totalPages: 1,
				currentSymbol: ''
			};
		},
		computed: {
			filteredTransactions() {
				if (!this.currentSymbol) return this.transactions;
				return this.transactions.filter(transaction => transaction.symbol === this.currentSymbol);
			}
		},
		watch: {
			filteredTransactions() {
				this.updateTotalPages();
			},
			selectedTab(tab) {
				if(tab === 'settings') {
					this.walletData = null;
					this.transactions = [];
				}
			}
		},
		methods: {
			getWallet(walletData) {
				this.walletData = walletData;
			},
			updateBalance({
				balance,
				balancePending
			}) {
				this.balance = balance;
				this.balancePending = balancePending;
			},
			updateTransactions(transactions) {
				this.transactions = transactions;
				this.updateTotalPages();
			},
			updateTotalPages() {
				this.totalPages = Math.ceil(this.filteredTransactions.length / 5); // Assuming 5 items per page
			},
			updateCurrentPage(page) {
				this.currentPage = page;
			},
			updateSymbol(symbol) {
				this.currentSymbol = symbol;
			},
			updateMgdbBalance(mgxBalance) {
				this.mgxBalance = mgxBalance;
			},
			refreshWallet() {
				this.fetchWallet = new Date().getTime();
			}
		}
	};
</script>
<style scoped>
	/* Your styles here */
</style>