<template>
	<div class="send-component" :class="{ 'pending': balancePending > 0 || balancePending < 0 }">
		<div v-if="balance === 0 && !transactionStatus">
			<div class="no-balance">
				<div class="form-group">
					<strong class="yellow">You have nothing to send!</strong>
				</div>
				<div class="form-group"> Maybe try to buy {{ currentSymbol }} first? </div>
				<button class="btn"> Buy {{ currentSymbol }} </button>
			</div>
		</div>
		<div v-if="balance > 0 && mgxBalance < fee && !transactionStatus">
			<div class="no-balance">
				<div class="form-group">
					<strong class="yellow">You don't have enough {{ nativeSymbol }} for the fee!</strong>
				</div>
				<button class="btn"> Buy {{ nativeSymbol }} </button>
			</div>
		</div>
		<div v-if="balance > 0 && mgxBalance > fee && !isReviewing && !transactionStatus">
			<div class="form-group">
				<label for="receiver">Receiver Address</label>
				<input type="text" id="receiver" v-model="receiver" @input="validateForm" placeholder="Enter receiver address" />
			</div>
			<div class="form-group">
				<label for="amount">Amount</label>
				<input type="number" id="amount" v-model="amount" :disabled="!fee" @input="validateForm" placeholder="Enter amount" />
				<p class="total-amount">Fee: <span v-if="!fee">Calculating...</span><span v-if="fee">~{{ fee }} MGX</span> (Balance: {{ mgxBalance }})</p>
			</div>
			<div class="form-group">
				<label for="data">Data (Optional)</label>
				<input type="text" id="data" v-model="data" placeholder="Enter additional data" />
			</div>
		</div>
		<div v-if="isReviewing && !transactionStatus" class="review-section">
			<div class="form-group">
				<strong class="yellow">Review Transaction</strong>
			</div>
			<div class="form-group">
				<label for="receiver">Receiver: <span>{{ receiver }}</span>
				</label>
			</div>
			<div class="form-group">
				<label for="amount">Amount: <span>{{ amount }} {{ currentSymbol }}</span></label>
			</div>
			<div v-if="data" class="form-group">
				<label for="data">Data:</label>
				<span>{{ data }}</span>
			</div>
			<div class="form-group">
				<label for="fee">Fee: <span>{{ fee }} MGX</span>
				</label>
			</div>
			<div class="form-group">
				<label for="password">Enter Password</label>
				<input type="password" id="password" v-model="password" placeholder="Enter your password" />
			</div>
		</div>
		<div class="send-btn-wrapper">
			<button v-if="balance > 0 && mgxBalance > fee && !isReviewing && !transactionStatus" class="btn" @click="reviewTransaction" :disabled="!isFormValid">Review</button>
			<button v-if="isReviewing && !transactionStatus" class="btn" @click="confirmTransaction" :disabled="!password">Confirm</button>
			<button v-if="isReviewing && !transactionStatus" class="btn" @click="goBack">Back</button>
		</div>
		<div v-if="transactionStatus" class="transaction-status">
			<div v-if="transactionStatus.error" class="form-group">
				<strong class="transaction-failed">Error: {{ transactionStatus.error }}</strong>
			</div>
			<div v-if="transactionStatus.txid" class="confirmation">
				<strong>Transaction Sent!</strong>
			</div>
			<div v-if="transactionStatus.txid" class="form-group">
				<label for="address">Transaction ID</label>
				<input type="text" id="txid" :value="transactionStatus.txid" readonly class="form-input transparent-input">
			</div>
			<div v-if="transactionStatus.txid" class="form-group">
				<a :href="`https://explorer.mginx.network/?search=${transactionStatus.txid}`" target="_blank">View on Explorer</a>
			</div>
			<button class="btn" @click="newTransaction">New Transaction</button>
		</div>
	</div>
</template>

<script>
	import CryptoJS from 'crypto-js';
	import MginDBClient from '@/plugins/mgindb';
	export default {
		props: ['balance', 'balancePending', 'currentSymbol', 'nativeSymbol', 'mgxBalance'],
		emits: ['refreshWallet'],
		data() {
			return {
				receiver: '',
				amount: '',
				fee: 0,
				data: '',
				password: '',
				isFormValid: false,
				isReviewing: false,
				transactionStatus: null,
			};
		},
		watch: {
			currentSymbol() {
				this.newTransaction();
			}
		},
		mounted() {
			this.fetchFee();
		},
		methods: {
			async fetchFee() {
				const client = new MginDBClient();
				try {
					const response = await client.getFee();
					this.fee = parseFloat(response);
				} catch (error) {
					console.error('Failed to fetch fee:', error);
					this.fee = 0.05; // Default fee
				}
			},
			validateForm() {
				let totalAmount = this.amount;
				if (this.currentSymbol === 'MGX') {
					totalAmount += this.fee;
				}
				if (totalAmount > this.balance) {
					this.amount = this.balance - (this.currentSymbol === 'MGX' ? this.fee : 0);
					totalAmount = this.amount;
				}
				this.isFormValid = this.receiver && totalAmount > 0;
			},
			reviewTransaction() {
				this.isReviewing = true;
			},
			goBack() {
				this.isReviewing = false;
				this.password = '';
			},
			async confirmTransaction() {
				try {
					const encryptedPrivateKey = localStorage.getItem('encryptedPrivateKey');
					const bytes = CryptoJS.AES.decrypt(encryptedPrivateKey, this.password);
					const privateKey = bytes.toString(CryptoJS.enc.Utf8);
					if (!privateKey) {
						throw new Error('Incorrect password');
					}
					const client = new MginDBClient();
					const payload = {
						"private_key": privateKey,
						"receiver": this.receiver,
						"amount": this.amount,
						"symbol": this.currentSymbol,
						"data": this.data,
						"fee": this.fee
					}
					const response = await client.sendTxn(JSON.stringify(payload));
					this.transactionStatus = JSON.parse(response);
					if (this.transactionStatus.txid) {
						this.$emit('refreshWallet', this.currentSymbol);
					}
					if (this.transactionStatus.error) {
						this.isReviewing = false;
					}
				} catch (error) {
					alert(`Failed to send transaction: ${error.message}`);
				}
			},
			newTransaction() {
				this.receiver = '';
				this.amount = '';
				this.data = '';
				this.password = '';
				this.isFormValid = false;
				this.isReviewing = false;
				this.transactionStatus = null;
			},
		},
	};
</script>

<style scoped></style>
