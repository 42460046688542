<template>
	<div class="transaction-details" :class="{ 'pending': isPending }">
		<div class="form-group">
			<strong class="yellow pointer" @click="backToList">
				<i class="fas fa-angle-left"></i> Back to transactions </strong>
		</div>
		<div class="form-group">
			<a :href="`https://explorer.mginx.network/?search=${transaction.txid}`" target="_blank">View on Explorer</a>
		</div>
		<div class="form-group">
			<strong>Transaction ID:</strong>
			<input type="text" id="txid" :value="transaction.txid" readonly class="transparent-input">
		</div>
		<div class="form-group" v-if="transaction.contract_hash">
			<strong>Contract:</strong>
			<input type="text" id="txid" :value="transaction.contract_hash" readonly class="transparent-input">
		</div>
		<div class="form-group" v-if="transaction.action">
			<strong>Action: </strong> {{ transaction.action }}
		</div>
		<div class="form-group">
			<strong>Date: </strong>{{ formatDate(transaction.timestamp) }}
		</div>
		<div class="form-group">
			<strong>Amount: </strong>
			<span>{{ formattedAmount.integer }}</span>
			<span v-if="formattedAmount.decimal > 0" class="small-decimal">.{{ formattedAmount.decimal }}</span> {{ transaction.symbol }}
		</div>
		<div class="form-group">
			<strong>Fee: </strong>
			<span>{{ formattedFee.integer }}</span>
			<span v-if="formattedFee.decimal > 0" class="small-decimal">.{{ formattedFee.decimal }}</span> MGX
		</div>
		<div v-if="showReceiver" class="form-group">
			<strong>To: </strong>{{ transaction.receiver }}
		</div>
		<div v-if="showSender" class="form-group">
			<strong>From: </strong>{{ transaction.sender }}
		</div>
		<div>
			<strong>Status: </strong>{{ transaction.confirmed ? 'Confirmed' : 'Pending' }}
		</div>
	</div>
</template>
<script>
	export default {
		props: ['walletData', 'transaction', 'currentSymbol', 'balancePending'],
		data() {
			return {
				walletAddress: localStorage.getItem('address')
			};
		},
		computed: {
			isPending() {
				return this.balancePending !== 0;
			},
			formattedAmount() {
				return this.formatAmount(this.transaction.amount);
			},
			formattedFee() {
				return this.formatAmount(this.transaction.fee);
			},
			showReceiver() {
				return (this.transaction.receiver && this.walletAddress === this.transaction.sender && !this.isContractAction) || !this.transaction.sender;
			},
			showSender() {
				return this.walletAddress === this.transaction.receiver && !this.isContractAction && this.transaction.sender;
			},
			isContractAction() {
				const specialActions = ['create_contract', 'contract_mint', 'block_reward'];
				return specialActions.includes(this.transaction.action);
			}
		},
		methods: {
			formatDate(timestamp) {
				const date = new Date(timestamp * 1000);
				return date.toISOString().split('T')[0];
			},
			formatAmount(amount) {
				const amountFloat = parseFloat(amount) / 1e8;
				const [integer, decimal = '00000000'] = amountFloat.toFixed(8).split('.');
				return {
					integer: new Intl.NumberFormat().format(integer),
					decimal
				};
			},
			backToList() {
				this.$emit('back-to-list');
			}
		}
	};
</script>
<style scoped></style>