<template>
	<div class="balance-component" v-if="currentSymbol">
		<!-- Balance View -->
		<div v-if="!showAssets" class="center-content">
			<p @click="toggleView" class="balance-view">
				<span class="logo-small">
					<img :src="`assets/symbols/${currentSymbol}.png`" class="rounded-logo">
				</span>
				<span class="yellow">{{ currentSymbol }}</span>
				<i class="fas fa-caret-down"></i>
			</p>
			<p class="strong">
				<span>{{ formattedBalanceInteger }}</span>
				<span class="small-decimal">.{{ balanceDecimal }}</span>
			</p>
			<p v-if="(balancePendingInteger > 0 || balancePendingInteger < 0) || balancePendingDecimal !== '00000000'" class="pending">
				<span>
					<i class="fas fa-clock"></i>
				</span>
				<span>{{ formattedBalancePendingInteger }}</span>
				<span class="small-decimal">.{{ balancePendingDecimal }}</span>
			</p>
		</div>
		<!-- Assets View -->
		<div v-else class="assets-container">
			<div class="assets-header">
				<p @click="toggleView" class="balance-view">
					<span class="yellow">Assets</span>
					<i class="fas fa-caret-down"></i>
				</p>
				<div class="search-container">
					<input type="text" v-model="searchQuery" placeholder="Search by symbol..." />
				</div>
			</div>
			<div class="assets-list">
				<div class="asset" v-for="(balance, symbol) in filteredBalances" :key="symbol" @click="selectBalance(balance, symbol)">
					<div class="logo-symbol">
						<span class="logo">
							<img :src="`assets/symbols/${symbol}.png`" class="rounded-logo">
						</span>
						<div class="symbol-price">
							<span class="symbol">{{ symbol }}</span>
							<div class="asset-price">
								<span>${{ balance.price }}</span>
								<span v-if="balance.change !== 0" class="price-change" :class="{'positive': balance.change > 0, 'negative': balance.change < 0}">
									{{ balance.change > 0 ? '+' : balance.change < 0 ? '-' : '' }}{{ balance.change }}% </span>
							</div>
						</div>
					</div>
					<div class="asset-balance">
						<div class="balance-main">
							<span class="small-integer">{{ formatAmount(balance.integer) }}</span>
							<span class="small-decimal">.{{ balance.decimal }}</span>
						</div>
						<div class="balance-pending" v-if="balance.pendingInteger !== '0' || balance.pendingDecimal !== '00000000'">
							<span>
								<i class="fas fa-clock"></i>
							</span>
							<span class="small-integer">{{ formatAmount(balance.pendingInteger) }}</span>
							<span class="small-decimal">.{{ balance.pendingDecimal }}</span>
						</div>
						<div class="balance-value">
							<span>${{ calculateBalanceValue(balance) }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<p class="wallet-address" v-if="!showAssets">
			{{ walletAddress }}
			<i v-if="!copiedToClipboard" class="fas fa-copy pointer" @click="copyToClipboard(walletAddress)"></i>
			<i v-if="copiedToClipboard" class="fas fa-check" @click="copyToClipboard(walletAddress)"></i>
		</p>
	</div>
</template>
<script>
	import MginDBClient from '@/plugins/mgindb';
	export default {
		props: ['fetchWallet'],
		emits: ['dispatch:wallet', 'update:balance', 'update:transactions', 'update:symbol', 'update:mgxBalance'],
		data() {
			return {
				walletAddress: localStorage.getItem('address'),
				balanceInteger: '0',
				balanceDecimal: '00000000',
				balancePendingInteger: '0',
				balancePendingDecimal: '00000000',
				currentSymbol: '',
				copiedToClipboard: false,
				showAssets: false,
				balances: {},
				searchQuery: ''
			};
		},
		created() {
			this.fetchWalletData();
		},
		watch: {
			fetchWallet: 'fetchWalletData',
			balances: {
				handler() {
					this.emitMgdBBalance();
				},
				deep: true
			}
		},
		computed: {
			formattedBalanceInteger() {
				return this.formatAmount(this.balanceInteger);
			},
			formattedBalancePendingInteger() {
				return this.formatAmount(this.balancePendingInteger);
			},
			filteredBalances() {
				if (!this.searchQuery) {
					return this.balances;
				}
				const query = this.searchQuery.toLowerCase();
				return Object.fromEntries(Object.entries(this.balances).filter(([symbol]) => symbol.toLowerCase().includes(query)));
			}
		},
		methods: {
			formatAmount(amount) {
				return new Intl.NumberFormat().format(amount);
			},
			calculateBalanceValue(balance) {
				const balanceValue = (parseFloat(`${balance.integer}.${balance.decimal}`) * balance.price).toFixed(2);
				return balanceValue;
			},
			async fetchWalletData() {
				try {
					const client = new MginDBClient();
					const wallet = await client.getWallet(this.walletAddress);
					if (wallet) {
						const walletData = wallet === 'Wallet not found' ? {} : JSON.parse(wallet);
						const balances = {};
						for (const [symbol, balanceObj] of Object.entries(walletData.balances)) {
							const balanceFloat = parseFloat(balanceObj.balance) / 1e8;
							const balancePendingFloat = parseFloat(balanceObj.balance_pending) / 1e8;
							const [integer, decimal = '00000000'] = balanceFloat.toFixed(8).split('.');
							const [pendingInteger, pendingDecimal = '00000000'] = balancePendingFloat.toFixed(8).split('.');
							// Assuming you have price and change in your balanceObj or need to fetch it separately
							const price = parseFloat(balanceObj.price) || 0; // Placeholder, replace with actual price fetching
							const change = parseFloat(balanceObj.change) || 0; // Placeholder, replace with actual change fetching
							balances[symbol] = {
								integer: integer,
								decimal,
								pendingInteger: pendingInteger,
								pendingDecimal,
								price,
								change
							};
						}
						this.balances = balances;
						if (!this.currentSymbol && Object.keys(balances).length > 0) {
							const firstSymbol = Object.keys(balances)[0];
							await this.selectBalance(balances[firstSymbol], firstSymbol);
						} else if (this.currentSymbol) {
							await this.selectBalance(balances[this.currentSymbol], this.currentSymbol);
						}
						this.$emit('dispatch:wallet', walletData);
						this.$emit('update:transactions', walletData.tx_data.reverse()); // Emit transactions
						this.emitMgdBBalance();
					}
				} catch (error) {
					console.error('Failed to fetch wallet data:', error);
				}
			},
			async selectBalance(balance, symbol) {
				this.balanceInteger = balance.integer;
				this.balanceDecimal = balance.decimal;
				this.balancePendingInteger = balance.pendingInteger;
				this.balancePendingDecimal = balance.pendingDecimal;
				this.currentSymbol = symbol;
				this.showAssets = false;
				this.$emit('update:balance', {
					balance: parseFloat(`${balance.integer}.${balance.decimal}`),
					balancePending: parseFloat(`${balance.pendingInteger}.${balance.pendingDecimal}`)
				});
				this.$emit('update:symbol', symbol);
			},
			toggleView() {
				this.showAssets = !this.showAssets;
			},
			copyToClipboard(data) {
				const input = document.createElement('input');
				input.value = data;
				document.body.appendChild(input);
				input.select();
				document.execCommand('copy');
				document.body.removeChild(input);
				this.copiedToClipboard = true;
				setTimeout(() => {
					this.copiedToClipboard = false;
				}, 2000);
			},
			emitMgdBBalance() {
				const mgxBalance = this.balances['MGX'] || {
					integer: '0',
					decimal: '00000000'
				};
				this.$emit('update:mgxBalance', parseFloat(`${mgxBalance.integer}.${mgxBalance.decimal}`));
			}
		}
	};
</script>
<style scoped>
	.assets-container {
		display: flex;
		flex-direction: column;
		height: 100vh;
		z-index: 1000;
	}

	.assets-header {
		position: sticky;
		top: 0;
		z-index: 1001;
	}

	.fa-caret-down {
		font-size: 14px;
		margin-left: 10px;
	}

	.search-container {
		text-align: center;
		margin: 10px 0 30px 0;
	}

	.search-container input {
		width: 80%;
	}

	.asset {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #444;
		padding: 10px 0;
		cursor: pointer;
	}

	.asset:last-child {
		border-bottom: none;
		padding-bottom: 0;
	}

	.logo-symbol {
		display: flex;
		align-items: center;
		margin-left: 10px;
	}

	.logo-symbol .logo {
		margin-right: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.symbol-price {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.logo-symbol .logo img {
		width: 40px;
		border-radius: 50%;
	}

	.logo-small img {
		width: 25px;
		border-radius: 50%;
		margin-right: 10px;
	}

	.center-content {
		text-align: center;
	}

	.balance-view {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.asset-balance {
		margin-right: 15px;
		text-align: right;
	}

	.small-integer {
		font-size: 14px;
	}

	.symbol-price .asset-price {
		font-size: 14px;
		margin-top: 2px;
		color: #b1afaf;
	}

	.symbol-price .price-change {
		margin-left: 5px;
		font-size: 12px;
	}

	.asset-balance .balance-pending {
		color: #b1afaf;
		font-size: 1em;
		margin-top: 5px;
	}
	.asset-balance .balance-value {
		color: #b1afaf;
		font-size: 14px;
		margin-top: 5px;
	}

	.fa-clock {
		margin-right: 5px;
	}
</style>