<template>
    <div class="container">
        <router-view></router-view>
    </div>
</template>

<script>

export default {
    components: {
    },
};
</script>

<style>
/* Add your global styles here */
</style>

