<template>
	<div v-if="currentSymbol">
		<div v-if="selectedTransaction">
			<TransactionDetailsComponent :walletData="walletData" :balancePending="balancePending" :transaction="selectedTransaction" @back-to-list="clearSelectedTransaction" />
		</div>
		<div v-else>
			<TransactionsComponent :walletData="walletData" :walletAddress="walletAddress" :transactions="filteredTransactions" :currentPage="currentPage" :totalPages="totalPages" :currentSymbol="currentSymbol" @update:currentPage="updateCurrentPage" @view-transaction-details="showTransactionDetails" />
		</div>
	</div>
</template>
<script>
	import TransactionsComponent from './TransactionsComponent.vue';
	import TransactionDetailsComponent from './TransactionDetailsComponent.vue';
	export default {
		props: ['walletData', 'balance', 'balancePending', 'transactions', 'currentPage', 'totalPages', 'currentSymbol'],
		components: {
			TransactionsComponent,
			TransactionDetailsComponent
		},
		data() {
			return {
				selectedTransaction: null,
				walletAddress: localStorage.getItem('address')
			};
		},
		computed: {
			filteredTransactions() {
				if (!this.currentSymbol) return this.transactions;
				return this.transactions.filter(transaction => transaction.symbol === this.currentSymbol);
			},
			paginatedTransactions() {
				const start = (this.currentPage - 1) * 5;
				const end = start + 5;
				return this.filteredTransactions.slice(start, end);
			}
		},
		methods: {
			updateCurrentPage(page) {
				this.$emit('update:currentPage', page);
			},
			showTransactionDetails(transaction) {
				this.selectedTransaction = transaction;
			},
			clearSelectedTransaction() {
				this.selectedTransaction = null;
			}
		}
	};
</script>
<style scoped>
	/* Your styles here */
</style>