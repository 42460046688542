import { createRouter, createWebHistory } from 'vue-router';
import HomeView from './views/HomeView.vue';
import DashboardView from './views/DashboardView.vue';

const routes = [
    { 
        path: '/', 
        component: HomeView,
        beforeEnter: (to, from, next) => {
            const address = localStorage.getItem('address');
            if (address) {
                next('/dashboard'); // Proceed if the address is found in local storage
            } else {
                next(); // Redirect to home page if no address is found
            }
        }
    },
    { 
        path: '/dashboard', 
        component: DashboardView,
        beforeEnter: (to, from, next) => {
            const address = localStorage.getItem('address');
            if (address) {
                next(); // Proceed if the address is found in local storage
            } else {
                next('/'); // Redirect to home page if no address is found
            }
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
