
<template>
  <HeaderComponent />
  <HomeComponent />
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import HomeComponent from '@/components/HomeComponent.vue'

export default {
  components: {
    HeaderComponent,
    HomeComponent
  }
}
</script>
