<template>
  <div class="home-container">
    <div class="card">
      <div class="tabs">
        <div :class="['tab', { active: activeTab === 'newWallet' }]" @click="setActiveTab('newWallet')"> New Wallet </div>
        <div :class="['tab', { active: activeTab === 'connect' }]" @click="setActiveTab('connect')"> Connect </div>
      </div>
      <div v-if="activeTab === 'newWallet'" class="tab-content">
        <div v-if="!walletDetails" class="welcome">
          <p class="yellow bold pb-10">Let's get you a new wallet</p>
        </div>
        <div v-if="walletDetails && !verificationStep" class="welcome">
          <p class="yellow">Save your details securely!</p>
        </div>
        <p v-if="verificationStep" class="welcome yellow">Verification</p>
        <div v-if="!walletDetails" class="form-group">
          <label for="passwordNew">Choose a password</label>
          <input type="password" id="passwordNew" v-model="password" class="form-input" @input="checkPasswordStrength">
          <div v-if="password" class="password-strength" :class="passwordStrengthClass">{{ passwordStrengthText }}</div>
        </div>
        <button v-if="!walletDetails && (passwordStrengthClass === 'medium' || passwordStrengthClass === 'strong' || passwordStrengthClass === 'very-strong')" @click="createNewWallet" class="btn">Create</button>
        <div v-if="walletDetails">
          <div v-if="!verificationStep" class="form-group">
            <label for="address">Address</label>
            <input type="text" id="address" :value="walletDetails.address" readonly class="form-input transparent-input">
          </div>
          <div v-if="!verificationStep" class="form-group">
            <label for="privateKey">Private Key</label>
            <input type="text" id="privateKey" :value="walletDetails.private_key" readonly class="form-input transparent-input">
          </div>
          <div v-if="!verificationStep" class="form-group">
            <label for="mnemonic">Mnemonic</label>
            <div id="mnemonic" class="mnemonic-tags">
              <span v-for="(word, index) in walletDetails.words.split(' ')" :key="index" class="mnemonic-tag">{{ index + 1 }}. {{ word }}</span>
            </div>
          </div>
          <button v-if="!verificationStep && !saved" @click="saveDetailsToFile" class="btn">Save</button>
          <button v-if="!verificationStep && saved" @click="startVerification" class="btn">Confirm</button>
          <div v-if="verificationStep">
            <p class="mnemonic-instructions">Enter the correct mnemonic words for the following positions:</p>
            <div class="mnemonic-grid">
              <div v-for="(number, index) in randomNumbers" :key="index" class="mnemonic-input-group">
                <input type="text" :id="'word' + number" :placeholder="'Word ' + number" v-model="userInput[number]" class="form-input" :class="{ incorrect: incorrectWords.includes(number) }">
              </div>
            </div>
            <button @click="verifyMnemonic" class="btn">Verify</button>
          </div>
        </div>
      </div>
      <div v-else-if="activeTab === 'connect'" class="tab-content">
        <p class="welcome yellow bold">Connect to your wallet</p>
        <form @submit.prevent="connectWallet">
          <div class="form-group">
            <label for="mnemonicInput">With Mnemonic</label>
            <input type="text" id="mnemonicInput" v-model="mnemonicInput" class="form-input">
          </div>
          <p class="connect-separator">____________ OR ____________</p>
          <div class="form-group">
            <label for="privateKeyInput">With Private Key</label>
            <input type="password" id="privateKeyInput" v-model="privateKeyInput" class="form-input">
          </div>
          <p class="connect-separator">__________ Security __________</p>
          <div class="form-group">
            <label for="passwordConnect">Password</label>
            <input type="password" id="passwordConnect" v-model="password" class="form-input" @input="checkPasswordStrength">
          </div>
          <button v-if="(mnemonicInput || privateKeyInput) && password" type="submit" class="btn">Connect</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    ref,
    computed
  } from 'vue';
  import {
    useRouter
  } from 'vue-router';
  import MginDBClient from '@/plugins/mgindb';
  import CryptoJS from 'crypto-js';
  export default {
    setup() {
      const activeTab = ref('newWallet');
      const mnemonicInput = ref('');
      const privateKeyInput = ref('');
      const walletDetails = ref(null);
      const verificationStep = ref(false);
      const randomNumbers = ref([]);
      const userInput = ref({});
      const incorrectWords = ref([]);
      const password = ref('');
      const passwordStrength = ref(0);
      const saved = ref(false);
      const router = useRouter();
      const client = new MginDBClient();
      const setActiveTab = (tab) => {
        activeTab.value = tab;
      };
      const checkPasswordStrength = () => {
        const pwd = password.value;
        let strength = 0;
        if (pwd.length > 5) strength++;
        if (pwd.length > 7) strength++;
        if (/[A-Z]/.test(pwd)) strength++;
        if (/[0-9]/.test(pwd)) strength++;
        if (/[^A-Za-z0-9]/.test(pwd)) strength++;
        passwordStrength.value = strength;
      };
      const passwordStrengthText = computed(() => {
        switch (passwordStrength.value) {
          case 0:
          case 1:
            return 'Weak';
          case 2:
            return 'Medium';
          case 3:
          case 4:
            return 'Strong';
          case 5:
            return 'Very Strong';
          default:
            return '';
        }
      });
      const passwordStrengthClass = computed(() => {
        switch (passwordStrength.value) {
          case 0:
          case 1:
            return 'weak';
          case 2:
            return 'medium';
          case 3:
          case 4:
            return 'strong';
          case 5:
            return 'very-strong';
          default:
            return '';
        }
      });
      const createNewWallet = async () => {
        try {
          const response = await client.newWallet();
          walletDetails.value = JSON.parse(response);
        } catch (error) {
          console.error('Failed to create new wallet:', error);
        }
      };
      const saveDetailsToFile = () => {
        const words = walletDetails.value.words.split(' ').map((word, index) => `${index + 1}. ${word}`).join('\n');
        const details = `Address: ${walletDetails.value.address}\nPrivate Key: ${walletDetails.value.private_key}\nMnemonic:\n${words}`;
        const blob = new Blob([details], {
          type: 'text/plain'
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'mginx-wallet-details.txt';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        saved.value = true;
      };

      const startVerification = () => {
        verificationStep.value = true;
        const numbers = Array.from({
          length: 12
        }, (_, i) => i + 1);
        randomNumbers.value = numbers.sort(() => 0.5 - Math.random()).slice(0, 6);
      };

      const verifyMnemonic = () => {
        const mnemonicWords = walletDetails.value.words.split(' ');
        incorrectWords.value = [];
        for (let number of randomNumbers.value) {
          if (userInput.value[number] !== mnemonicWords[number - 1]) {
            incorrectWords.value.push(number);
          }
        }
        if (incorrectWords.value.length > 0) {
          alert('Mnemonic verification failed. Please try again.');
          return;
        }
        // Save encrypted private key and address in local storage only if verification succeeds
        const encryptedPrivateKey = CryptoJS.AES.encrypt(walletDetails.value.private_key, password.value).toString();
        localStorage.setItem('encryptedPrivateKey', encryptedPrivateKey);
        localStorage.setItem('address', walletDetails.value.address);
        alert('Mnemonic verified successfully!');
        router.push('/dashboard');
      };

      const connectWallet = async () => {
        try {
          const inputValue = mnemonicInput.value || privateKeyInput.value;
          const response = await client.connectWallet(inputValue);
          const wallet = JSON.parse(response);
          if (wallet.status === 'success') {
            const encryptedPrivateKey = CryptoJS.AES.encrypt(wallet.private_key, password.value).toString();
            localStorage.setItem('encryptedPrivateKey', encryptedPrivateKey);
            localStorage.setItem('address', wallet.address);
            router.push('/dashboard');
          } else {
            alert(`Failed to connect wallet: ${wallet.message}`);
          }
        } catch (error) {
          alert(`Failed to connect wallet: ${error}`);
        }
      };
      return {
        activeTab,
        mnemonicInput,
        privateKeyInput,
        walletDetails,
        verificationStep,
        randomNumbers,
        userInput,
        incorrectWords,
        password,
        passwordStrengthText,
        passwordStrengthClass,
        setActiveTab,
        createNewWallet,
        startVerification,
        verifyMnemonic,
        connectWallet,
        checkPasswordStrength,
        saveDetailsToFile,
        saved
      };
    }
  };
</script>
<style scoped>
  .home-container {
    padding: 20px;
    margin-top: 140px;
    text-align: center;
  }

  .welcome {
    margin-bottom: 30px;
  }

  .tabs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    background-color: #444;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
  }

  .tab {
    flex: 1;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #444;
    color: #eee;
    text-align: center;
    border-right: 1px solid #555;
  }

  .tab:last-child {
    border-right: none;
  }

  .tab.active {
    background-color: #1e1e1e;
    border-bottom: 1px solid #1e1e1e;
  }

  .tab-content {
    padding: 0 20px 20px 20px;
  }

  .form-group {
    margin-bottom: 15px;
    text-align: left;
  }

  .form-group label {
    display: block;
    margin-bottom: 15px;
    color: #ccc;
  }

  .form-input {
    font-size: 16px;
    width: 100%;
    padding: 10px !important;
    border: 1px solid #444;
    border-radius: 5px;
    background-color: #333;
    color: #eee;
    outline: none;
    box-sizing: border-box;
  }

  .form-input.incorrect {
    border-color: red;
  }

  input::placeholder,
  textarea::placeholder {
    color: #b1afaf;
  }

  .transparent-input {
    font-size: 16px;
    background-color: transparent;
    color: #eee;
    border: none;
    padding-left: 0 !important;
    outline: none;
  }

  .mnemonic-tags {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 20px;
  }

  .mnemonic-tag {
    padding: 5px 10px;
    background-color: #444;
    border-radius: 5px;
    color: #eee;
  }

  .mnemonic-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 20px;
    margin-right: 10px;
  }

  .mnemonic-input-group {
    display: flex;
    flex-direction: column;
  }

  .mnemonic-instructions {
    margin-bottom: 30px;
  }

  .connect-separator {
    margin-top: 35px;
    margin-bottom: 40px;
  }

  .password-strength {
    margin-top: 10px;
  }

  .password-strength.weak {
    color: #f62727;
  }

  .password-strength.medium {
    color: orange;
  }

  .password-strength.strong {
    color: #02c205;
  }

  .password-strength.very-strong {
    color: #01a904;
  }
</style>