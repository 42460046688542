<template>
    <header class="header">
        <div class="header-title">
            <img src="/logo-192x192.png" alt="Logo" class="logo">
            <h1>MginX Wallet</h1>
        </div>
        <div v-if="address" @click="logout" class="fas fa-right-from-bracket"></div>
    </header>
</template>

<script>
export default {
    data() {
        return {
            address: localStorage.getItem('address'),
            navActive: false,
        };
    },
    methods: {
        toggleNav() {
            this.navActive = !this.navActive;
        },
        logout() {
            if(confirm('Do you want to logout?')) {
                localStorage.removeItem('address');
                localStorage.removeItem('encryptedPrivateKey');
                this.$router.push('/');
            }
        }
    },
};
</script>

<style scoped>

</style>

