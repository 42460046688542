<template>
  <HeaderComponent />
  <DashboardComponent :selectedTab="activeTab" @update:selectedTab="updateActiveTab" />
  <FooterComponent :activeTab="activeTab" @update:activeTab="updateActiveTab" />
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import DashboardComponent from '@/components/DashboardComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';

export default {
  components: {
    HeaderComponent,
    DashboardComponent,
    FooterComponent
  },
  data() {
    return {
      activeTab: 'wallet'
    };
  },
  methods: {
    updateActiveTab(tab) {
      this.activeTab = tab;
    }
  }
}
</script>

<style scoped>
/* Your styles... */
</style>
