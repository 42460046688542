<template>
	<div v-if="walletData" class="transactions-component" :class="{ 'pending': walletData.balances[currentSymbol]?.balance_pending > 0 || walletData.balances[currentSymbol]?.balance_pending < 0 }">
		<div v-if="paginatedTransactions.length === 0">
			<div class="no-tx">
				<div class="form-group">No transaction yet</div>
			</div>
		</div>
		<TransactionComponent v-for="transaction in paginatedTransactions" :key="transaction.txid" :transaction="transaction" :wallet-address="walletAddress" :currentSymbol="currentSymbol" @view-details="viewTransactionDetails" />
		<div class="pagination-controls" v-show="paginatedTransactions.length && totalPages > 1">
			<button class="btn" @click="goToFirstPage" :disabled="currentPage === 1">First</button>
			<button class="btn" @click="goToPreviousPage" :disabled="currentPage === 1">
				<i class="fas fa-angle-left"></i>
			</button>
			<span class="pages">{{ currentPage }} of {{ totalPages }}</span>
			<button class="btn" @click="goToNextPage" :disabled="currentPage === totalPages">
				<i class="fas fa-angle-right"></i>
			</button>
			<button class="btn" @click="goToLastPage" :disabled="currentPage === totalPages">Last</button>
		</div>
	</div>
</template>
<script>
	import TransactionComponent from './TransactionComponent.vue';
	export default {
		components: {
			TransactionComponent
		},
		props: ['walletData', 'walletAddress', 'transactions', 'currentPage', 'currentSymbol', 'totalPages'],
		computed: {
			filteredTransactions() {
				if (!this.currentSymbol) return this.transactions;
				return this.transactions.filter(transaction => transaction.symbol === this.currentSymbol);
			},
			paginatedTransactions() {
				const start = (this.currentPage - 1) * 5;
				const end = start + 5;
				return this.filteredTransactions.slice(start, end);
			}
		},
		methods: {
			goToFirstPage() {
				this.$emit('update:currentPage', 1);
			},
			goToPreviousPage() {
				if (this.currentPage > 1) {
					this.$emit('update:currentPage', this.currentPage - 1);
				}
			},
			goToNextPage() {
				if (this.currentPage < this.totalPages) {
					this.$emit('update:currentPage', this.currentPage + 1);
				}
			},
			goToLastPage() {
				this.$emit('update:currentPage', this.totalPages);
			},
			viewTransactionDetails(transaction) {
				this.$emit('view-transaction-details', transaction);
			}
		}
	};
</script>
<style scoped>
	/* Your styles here */
</style>