<template>
    <div class="receive-component" :class="{ 'pending': balancePending > 0 || balancePending < 0 }">
      <div class="qr-code">
        <div class="form-group qr-wrapper">
          <qrcode-vue :value="address" :size="200" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import QrcodeVue from 'qrcode.vue';
  
  export default {
    components: {
      QrcodeVue,
    },
    props: ['balancePending'],
    data() {
      return {
        balance: 0,
        balance_pending: 0,
        address: localStorage.getItem('address') || '',
      };
    },
    methods: {
      updateBalance(balance, balance_pending) {
        this.balance = balance;
        this.balance_pending = balance_pending;
      }
    },
  };
  </script>
  
  <style scoped>
 
  </style>
  