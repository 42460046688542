<template>
    <div class="footer">
      <div 
        v-for="(item, index) in footerItems" 
        :key="index" 
        :class="['footer-item', { active: activeTab === item.name }]" 
        @click="setActiveTab(item.name)">
        <i class="icon fas" :class="item.icon"></i>
        <div class="text">{{ item.text }}</div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      activeTab: {
        type: String,
        required: true
      }
    },
    emits: ['update:activeTab'],
    data() {
      return {
        footerItems: [
          { name: 'wallet', icon: 'fa-wallet', text: 'Wallet' },
          { name: 'send', icon: 'fa-arrow-up', text: 'Send' },
          { name: 'receive', icon: 'fa-arrow-down', text: 'Receive' },
          { name: 'settings', icon: 'fa-cog', text: 'Settings' },
        ]
      };
    },
    methods: {
      setActiveTab(tab) {
        this.$emit('update:activeTab', tab);
      }
    }
  };
  </script>
  
  <style scoped>
  
  </style>
  